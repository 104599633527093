import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '../../base/Box';
import Banner from '../Banner';
import LinkTo from '../LinkTo';
import Title from '../Title';

const ImageCard = ({
  card,
  cardColSx,
  cardSx,
  cardImageSx,
  cardInnerSx,
  cardInnerHoverSx,
  subTitleStyled,
}) => {
  const [onHover, setOnHover] = useState(false);
  const cardColStyled = {
    color: 'white',
    display: 'flex',
    flexDirection: 'columns',
    maxWidth: [
      'calc(100% / 2)',
      null,
      null,
      'calc(100% / 3)',
      'calc(100% / 4)',
    ],
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'center',
    width: '100%',
    ...cardColSx,
  };
  const cardStyled = {
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '243px',
    width: '100%',
    ...cardSx,
  };
  const cardInnerHoverStyled = onHover
    ? { bg: 'rgba(0, 0, 0, 0.75)', ...cardInnerHoverSx }
    : {};
  const cardInnerStyled = {
    alignItems: 'center',
    bg: 'rgba(0,0,0, 0.3)',
    bottom: 0,
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    left: 0,
    margin: '0 auto',
    p: 3,
    position: 'absolute',
    right: 0,
    top: 0,
    transition: 'all .45s 0s ease',
    width: '100%',
    ...cardInnerSx,
    ...cardInnerHoverStyled,
  };
  const imageStyled = {
    opacity: 'unset',
    ...cardImageSx,
  };
  const animatedStyled = {
    mb: [null, null, null, onHover ? '0%' : '-25%', null],
    opacity: [null, null, null, onHover ? '1' : '0', null],
    overflow: 'hidden',
    transform: [
      null,
      null,
      null,
      onHover ? 'translateY(0)' : 'translateY(8rem)',
      null,
    ],
    transition: [
      null,
      null,
      null,
      onHover
        ? 'margin-bottom .45s 0s ease, all .45s 0s ease'
        : 'all .45s 0s ease',
      null,
    ],
  };
  const titleStyled = {
    color: 'inherit',
    fontSize: '16px',
    fontWeight: 'medium',
    mb: 0,
  };
  const linkStyled = {
    borderBottom: '1px solid',
    borderColor: 'warning',
    fontFamily: 'primary',
    fontSize: '13px',
    textTransform: 'capitalize',
  };
  const subStyled = {
    fontSize: '14px',
    fontWeight: 'bold',
    ...subTitleStyled,
  };
  return (
    <Box sx={cardColStyled}>
      <Banner bgImage={card.image} imageSx={imageStyled} sx={cardStyled}>
        <Box
          onMouseEnter={() => setOnHover(true)}
          onMouseLeave={() => setOnHover(false)}
          sx={cardInnerStyled}
        >
          {card.title && (
            <Title
              sx={{
                ...titleStyled,
              }}
            >
              {card.title}
            </Title>
          )}
          <Box sx={animatedStyled}>
            {card.details && (
              <Title
                as="h3"
                sx={{
                  ...titleStyled,
                  fontSize: 3,
                  my: 4,
                  textTransform: 'capitalize',
                  ...subStyled,
                }}
              >
                {card.details}
              </Title>
            )}
            {card.linkTitle && (
              <>
                {card.link ? (
                  <LinkTo sx={linkStyled} to={card.link}>
                    {card.linkTitle}
                  </LinkTo>
                ) : (
                  <Box
                    sx={{
                      fontSize: 1,
                      fontStyle: 'normal',
                      fontWeight: 'regular',
                      lineHeight: '14px',
                    }}
                  >
                    {card.linkTitle}
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>
      </Banner>
    </Box>
  );
};

ImageCard.propTypes = {
  card: PropTypes.shape({
    details: PropTypes.string,
    image: PropTypes.string,
    link: PropTypes.string,
    linkTitle: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  cardColSx: PropTypes.shape({}),
  cardImageSx: PropTypes.shape({}),
  cardInnerHoverSx: PropTypes.shape({}),
  cardInnerSx: PropTypes.shape({}),
  cardSx: PropTypes.shape({}),
  subTitleStyled: PropTypes.shape({}),
};

ImageCard.defaultProps = {
  cardColSx: {},
  cardImageSx: {},
  cardInnerHoverSx: {},
  cardInnerSx: {},
  cardSx: {},
  subTitleStyled: {},
};

export default ImageCard;

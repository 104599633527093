import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../base/Box';
import ImageCard from '../ImageCard';

const HoverCards = ({ cards, wrapperSx, ...rest }) => {
  const cardWrapperStyled = {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    ...wrapperSx,
  };
  return (
    <>
      <Box sx={cardWrapperStyled}>
        {cards.map((card, index) => (
          <ImageCard key={`hover-card-${index}`} card={card} {...rest} />
        ))}
      </Box>
    </>
  );
};

HoverCards.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  wrapperSx: PropTypes.shape({}),
};

HoverCards.defaultProps = {
  wrapperSx: {},
};

export default HoverCards;

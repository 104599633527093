import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import Box from '../../base/Box';
import SEO from '../SEO';
import ExperienceContainer from '../../containers/ExperienceContainer';
import Container from '../Container';
import ContentArea from '../ContentArea';
import Title from '../Title';
import ListGroup from '../ListGroup';
import SubTitle from '../SubTitle';
import HoverCards from '../HoverCards';
import CallUs from '../CallUsButton';

const Experience = ({ page }) => {
  const setBannerImgWidth = (totalCards) => {
    if (totalCards === 3) {
      return {
        '&:nth-child(2)': {
          flex: [null, null, null, 2],
        },
        minWidth: ['calc(100% / 2)', null, 'calc(100% / 4)'],
      };
    }
  };
  return (
    <ExperienceContainer>
      {(
        experiences,
        totalExperienceCount,
        experienceImages,
        commonExperienceInfo
      ) => {
        const pageName = page || 'k-12';
        const imageId = `${pageName}_images`;
        const commonExperienceData = commonExperienceInfo[0];
        const experienceData = experiences
          .map((experience) => {
            return experience.id === pageName ? experience : null;
          })
          .find((exp) => exp);
        const experienceDataImages = experienceImages
          .map((ele) => {
            return ele.id === imageId ? ele : null;
          })
          .find((el) => el);
        const projects = get(experienceData, 'projects', []);
        return (
          <Box>
            <SEO />
            {/* <SEO description="" title={`${pageName} | Experience`} /> */}
            {experienceDataImages.images.length > 0 && (
              <HoverCards
                cardColSx={{
                  flex: 1,
                  maxWidth: '100%',
                  minWidth: ['calc(100% / 2)', null, '0'],
                  ...setBannerImgWidth(experienceDataImages.images.length),
                }}
                cardInnerSx={{
                  bg: ['rgba(0,0,0, 0.3)', null, null, 'unset'],
                }}
                cards={experienceDataImages.images}
                cardSx={{
                  minHeight: '252px',
                }}
                subTitleStyled={{
                  color: 'white',
                  fontFamily: 'primary',
                  fontSize: 2,
                  fontStyle: 'normal',
                  fontWeight: 'regular',
                  lineHeight: '17px',
                }}
              />
            )}

            {totalExperienceCount && (
              <Container
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  mb: 10,
                  mt: '71px',
                  width: ['100%', null, null, '80%', 'maxWidths.content'],
                }}
              >
                <Title
                  sx={{
                    color: 'grays.4',
                    fontFamily: 'tertiary',
                    fontSize: 8,
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    lineHeight: '33px',
                    mb: '23px',
                  }}
                >
                  {`${get(experienceData, 'name', '')}`}
                </Title>

                <SubTitle
                  sx={{
                    color: 'warning',
                    fontFamily: 'tertiary',
                    fontSize: 4,
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    lineHeight: '21px',
                    mb: '59px',
                  }}
                >
                  {`${get(commonExperienceData, 'subtitle', '')}`}
                </SubTitle>

                {/* Hide filter */}
                {/*
                <ListGroup
                  listSx={{
                    flex: 1,
                    maxWidth: ['100%', null, null, '33.33%', null],
                    mb: 4,
                    mr: 4,
                  }}
                  sx={{
                    display: 'flex',
                    flex: 'auto',
                    flexDirection: ['column', null, null, 'row', null],
                  }}
                  title="Featured Projects"
                  titleSx={{
                    color: 'grays.3',
                    flex: 1,
                    fontSize: 2,
                    fontWeight: 'bold',
                    maxWidth: ['100%', null, null, '25%', null],
                    mb: 4,
                    mr: 0,
                  }}
                  wrapperSx={{
                    flexDirection: ['column', null, null, 'row', null],
                    mb: 7,
                    mr: -2,
                    pageBreakInside: 'avoid',
                  }}
                >
                  <Field
                    as="select"
                    name="category"
                    options={[
                      {
                        label: 'option 1',
                        value: 'option 1',
                      },
                      {
                        label: 'option 2',
                        value: 'option 2',
                      },
                      {
                        label: 'option 3',
                        value: 'option 3',
                      },
                    ]}
                  />
                  <Field
                    as="select"
                    name="location"
                    options={[
                      {
                        label: 'Location 1',
                        value: 'Location 1',
                      },
                      {
                        label: 'Location 2',
                        value: 'Location 2',
                      },
                      {
                        label: 'Location 3',
                        value: 'Location 3',
                      },
                    ]}
                  />
                  <Field name="search" placeholder="Search by keyword" />
                </ListGroup>
                */}

                <ContentArea
                  sx={{
                    '> div': {
                      width: ['100%', null, '50%', '33.33%', '25%'],
                    },
                    columnCount: 'unset',
                    display: 'flex',
                    flexWrap: 'wrap',
                    mb: -5,
                    mr: -5,
                    width: 'unset',
                  }}
                >
                  {projects.length
                    ? projects.map((project, index) => {
                        return (
                          <Box key={`project-${index}`}>
                            {project.category && (
                              <Title
                                sx={{
                                  color: 'grays.3',
                                  fontFamily: 'tertiary',
                                  fontSize: 4,
                                  fontStyle: 'italic',
                                  fontWeight: 'bold',
                                  lineHeight: '21px',
                                  mb: 5,
                                  mt: 4,
                                  width: '100%',
                                }}
                              >
                                {project.category}
                              </Title>
                            )}
                            {project.line1 && (
                              <ListGroup
                                key={`data-${
                                  project.category || project.line1
                                }-${index}`}
                                sx={{
                                  fontWeight: 'regular',
                                }}
                                title={project.line1}
                                titleSx={{
                                  color: 'grays.3',
                                  fontFamily: 'tertiary',
                                  fontSize: 2,
                                  fontStyle: 'normal',
                                  fontWeight: 'bold',
                                  lineHeight: '20px',
                                  mb: 0,
                                }}
                                wrapperSx={{
                                  breakInside: 'avoid',
                                  color: 'grays.3',
                                  fontFamily: 'tertiary',
                                  fontSize: 2,
                                  fontStyle: 'normal',
                                  fontWeight: 'regular',
                                  lineHeight: '20px',
                                  mb: 4,
                                  pageBreakInside: 'avoid',
                                  pr: 5,
                                  textTransform: 'uppercase',
                                }}
                              >
                                {project.line2 && <span>{project.line2}</span>}
                                {project.line3 && <span>{project.line3}</span>}
                                {project.line4 && <span>{project.line4}</span>}
                              </ListGroup>
                            )}
                          </Box>
                        );
                      })
                    : ''}
                </ContentArea>
              </Container>
            )}
            <Box
              sx={{
                bg: 'grays.0',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                minHeight: [null, null, null, '377px', null],
                px: [2, null, null, 'unset', null],
                py: '79px',
              }}
            >
              <Title
                sx={{
                  color: 'grays.3',
                  fontFamily: 'tertiary',
                  fontSize: 9,
                  fontStyle: 'normal',
                  fontWeight: 'black',
                  lineHeight: [null, null, null, null, '20px'],
                  mb: '19px',
                  textAlign: 'center',
                }}
              >
                {commonExperienceData.bottomBannerTitle}
              </Title>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <CallUs />
              </Box>
            </Box>
          </Box>
        );
      }}
    </ExperienceContainer>
  );
};

Experience.propTypes = {
  page: PropTypes.string,
};

Experience.defaultProps = {
  page: 'k-12',
};

export default Experience;

import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import React from 'react';
import Box from '../../base/Box';

const Button = ({
  as,
  children,
  dummy,
  link,
  primary,
  sx,
  hoverSx,
  ...rest
}) => {
  const defaultSx = {
    '&:hover': {
      bg: 'grays.11',
      boxShadow: 2,
      ...hoverSx,
    },
    alignItems: 'center',
    bg: `${primary ? 'warning' : 'white'}`,
    borderRadius: 1,
    color: `${primary ? 'white' : 'grays.9'}`,
    display: 'inline-flex',
    fontFamily: 'secondary',
    fontSize: 1,
    fontWeight: 'semiBold',
    justifyContent: 'center',
    px: 4,
    py: 2,
    // transition: 'all .15s ease-in-out',
    ...sx,
  };

  return !dummy ? (
    <Box
      as={link ? Link : 'button'}
      sx={{
        ...defaultSx,
      }}
      to={link}
      {...rest}
    >
      {children}
    </Box>
  ) : (
    <Box
      as={as}
      sx={{
        ...defaultSx,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

Button.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node,
  dummy: PropTypes.bool,
  hoverSx: PropTypes.shape({}),
  link: PropTypes.string,
  primary: PropTypes.bool,
  sx: PropTypes.shape({}),
};

Button.defaultProps = {
  as: 'div',
  children: PropTypes.node,
  dummy: false,
  hoverSx: {},
  link: '',
  primary: false,
  sx: {},
};

export default Button;

import React from 'react';
import { Redirect, Router } from '@reach/router';
import NotFoundPage from './404';
import Experience from '../components/Experience';

const ExperienceRouter = (props) => (
  <Router>
    <Redirect from="/experience/" noThrow to="/experience/k-12" />
    {[
      'k-12',
      'federal',
      'state-and-local',
      'healthCare',
      'culture',
      'transportation-and-Infrastructure',
      'higher-ed',
      'military',
    ].map((page, index) => (
      <Experience
        key={index}
        {...{ ...props, page }}
        path={`/experience/${page}`}
      />
    ))}
    <NotFoundPage default />
  </Router>
);

export default ExperienceRouter;

import PropTypes from 'prop-types';
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

const ExperienceContainer = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allExperienceJson {
          edges {
            node {
              name
              projects {
                category
                id
                line1
                line2
                line3
                line4
              }
              id
            }
          }
          totalCount
        }
        allExperiencePageImagesJson {
          edges {
            node {
              id
              images {
                image
                details
                linkTitle
              }
            }
          }
        }
        allCommonExperienceContentJson {
          edges {
            node {
              bottomBannerTitle
              subtitle
            }
          }
        }
      }
    `}
    render={({
      allExperienceJson: {
        edges: experiences,
        totalCount: totalExperienceCount,
      },
      allExperiencePageImagesJson: { edges: experienceImages },
      allCommonExperienceContentJson: { edges: commonExperienceInfo },
    }) =>
      children(
        experiences.map((e) => ({
          ...e.node,
        })),
        totalExperienceCount,
        experienceImages.map((e) => ({
          ...e.node,
        })),
        commonExperienceInfo.map((e) => ({
          ...e.node,
        }))
      )
    }
  />
);

ExperienceContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default ExperienceContainer;

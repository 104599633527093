import React from 'react';
import PropTypes from 'prop-types';
import Title from '../Title';

const SubTitle = ({ as, children, sx }) => {
  const titleSx = {
    fontSize: 4,
    textTransform: 'none',
    ...sx,
  };
  return (
    <Title as={as} sx={titleSx}>
      {children}
    </Title>
  );
};

SubTitle.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node.isRequired,
  sx: PropTypes.shape({}),
};

SubTitle.defaultProps = {
  as: 'h3',
  sx: {},
};

export default SubTitle;
